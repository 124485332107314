import request from "@/utils/http/requset";

function replenishmentConfig(data) {
  return request({
    url: "/manage/recharge/replenishment",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

export default {
  replenishmentConfig
};
