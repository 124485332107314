<template>
  <section>
    <el-form
      :model="searchForm"
      :rules="rules"
      ref="searchForm"
      label-width="auto"
      class="demo-ruleForm"
    >
      <el-form-item label="渠道:" prop="channel">
        <el-col :span="9">
          <el-select v-model="searchForm.channel" clearable>
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item label="utr:" prop="utr">
        <el-col :span="4">
          <el-input
            v-model="searchForm.utr"
            placeholder="eg:utr"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="金额:" prop="money">
        <el-col :span="4">
          <el-input
            v-model="searchForm.money"
            placeholder="eg:1624.00"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search()">补单</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import api from "../../api/orderManage/replenishment";
import merchantApi from "@/api/merchantPayConfig/merchantApi";
export default {
  data() {
    return {
      searchForm: {
        merchantId: "",
        channel: "",
        cellphone: "",
        money: "",
        utr: ""
      },
      merchantList: [],
      channelList: [],
      rules: {
        merchantId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "change",
          },
        ],
        channel: [
          {
            required: true,
            message: "请选择渠道",
            trigger: "change",
          },
        ],
        cellphone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blue",
          },
        ],
        money: [
          {
            required: true,
            message: "请输入金额",
            trigger: "blue",
          },
        ]
      },
    };
  },
  methods: {
    bindGrid() {
      merchantApi.getMerchant({}).then((res) => {
        if (res.code == "0000") {
          this.merchantList = res.data.rows;
        }
      });
    },

    channelSelect(val) {
      merchantApi
        .getChannel({
          merchantId: val,
          isSelf: true,
        })
        .then((res) => {
          if (res.code == "0000") {
            this.channelList = res.data.split(",");
          }
        });
    },

    // 添加提交
    search() {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          let _data = {};
          let content = Object.assign({}, this.searchForm);
          content.money = parseFloat(content.money).toFixed(2);
          _data.content = content;
          api.replenishmentConfig(_data).then((res) => {
            if (res.code == "0000") {
              this.$alert(
                `<p>单号:${res.data.merordercode}</p><p>三方message:${res.data.msg}</p>`,
                "提示信息",
                {
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: "确定",
                  callback: (_action) => {
                    this.$refs.searchForm.resetFields();
                  },
                }
              );
              this.message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted: function() {
    this.bindGrid();
    this.channelSelect("");
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/base";
</style>
