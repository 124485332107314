import request from "@/utils/http/requset";

const merchantApi = {
  getMerchant(data) {
    return request({
      url: "/manage/merchant/remote",
      method: "post",
      ContentType: "application/json;charset=UTF-8",
      data: data
    });
  },

  getChannel(data) {
    return request({
      url: "/manage/payAccount/channels",
      method: "post",
      ContentType: "application/json;charset=UTF-8",
      data: data
    });
  }
};
export default merchantApi;
